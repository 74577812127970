// routes.js
import { createBrowserRouter, Navigate, useLocation } from "react-router-dom";
import { useUser } from "./UserContext";
import AppLayout from "../components/layout/AppLayout";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../components/layout/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import InboxPage from "../pages/InboxPage";
import AddUserAndVehicle from "../pages/NotificationPage";
import ActiveSalesPage from "../pages/ActiveSalesPage";
import ActiveCarsPage from "../pages/ActiveCarsPage";
import TodayTicketsPage from "../pages/TodayTicketsPage";
import TicketsPage from "../pages/tuckets";
import StationsPage from "../pages/StationsPage";
import Salesactivity from "../pages/Salesactivity";
import Users from "../pages/InboxPage";
import AddVehicle from "../pages/AddVehicle"; // Add this line
import InventoryManagement from "../pages/InventoryManagement"; // Add this line
import ErrorPage from "../pages/ErrorPage";
import LoadingSpinner from "../components/LoadingSpinner";
import { Suspense, useState, useEffect } from "react";

// Add this function before handleFirebaseError
export const isFirebaseError = (error) => {
  return error && 
    typeof error === 'object' && 
    'code' in error && 
    typeof error.code === 'string' && 
    error.code.startsWith('auth/');
};

// Add this function before the ProtectedRoute component
export const handleFirebaseError = (error) => {
  const errorCode = error?.code || 'unknown';
  
  const errorMap = {
    'auth/network-request-failed': {
      title: 'Network Error',
      message: 'Please check your internet connection and try again.'
    },
    'auth/user-disabled': {
      title: 'Account Disabled',
      message: 'This account has been disabled. Please contact support.'
    },
    'auth/user-not-found': {
      title: 'Authentication Error',
      message: 'User not found. Please check your credentials.'
    },
    'auth/invalid-credential': {
      title: 'Invalid Credentials',
      message: 'Please check your login information and try again.'
    }
  };

  return errorMap[errorCode] || {
    title: 'Error',
    message: 'An unexpected error occurred. Please try again later.'
  };
};

const ProtectedRoute = ({ element, allowedRoles }) => {
  const user = useUser();
  const location = useLocation();
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // Reset error when location changes
    setError(null);
  }, [location]);

  if (error) {
    const { title, message } = handleFirebaseError(error);
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <div className="p-8 bg-white rounded-lg shadow-md">
          <h1 className="text-4xl font-bold mb-4 text-red-600">{title}</h1>
          <p className="text-xl mb-4">{message}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Retry Connection
          </button>
        </div>
      </div>
    );
  }

  if (user === null) {
    return <LoadingSpinner />;
  }

  if (!user.uid) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  if (allowedRoles.includes(user.role)) {
    return element;
  } else {
    return <Navigate to="/dashboard/tickets" replace state={{ from: location }} />;
  }
};


export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LoginPage />
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <MainLayout />
          </Suspense>
        ),
        handle: {
          title: "Dashboard",
          description: "Main dashboard area"
        },
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <ProtectedRoute element={<DashboardPage />} allowedRoles={['admin']} />
          },
          {
            path: "overview",
            element: <ProtectedRoute element={<DashboardPage />} allowedRoles={['admin']} />
          },
          {
            path: "Users",
            element: <ProtectedRoute element={<Users />} allowedRoles={['admin']} />
          },
          {
            path: "inbox",
            element: <ProtectedRoute element={<Users />} allowedRoles={['admin']} />
          },
          {
            path: "AddUserAndVehicle",
            element: <ProtectedRoute element={<AddUserAndVehicle />} allowedRoles={['admin']} />
          },
          {
            path: "mortage",
            element: <ProtectedRoute element={<ActiveSalesPage />} allowedRoles={['admin']} />
          },
          {
            path: "AddVehicle",
            element: <ProtectedRoute element={<AddVehicle />} allowedRoles={['admin']} />
        },
        {
            path: "InventoryManagement",
            element: <ProtectedRoute element={<InventoryManagement />} allowedRoles={['admin']} />
        },
          {
            path: "carloan",
            element: <ActiveCarsPage />
          },
         
          {
            path: "stations",
            element: <ProtectedRoute element={<StationsPage />} allowedRoles={['admin']} />
          },
          {
            path: "SalesActivity",
            element: <ProtectedRoute element={<Salesactivity />} allowedRoles={['admin']} />
          },
          {
            path: "ticketsb",
            element: <ProtectedRoute element={<TicketsPage />} allowedRoles={['admin']} />
          },
          {
            path: "tickets",
            element: <TodayTicketsPage /> // Both admin and guest can access this
          },
          {
            path: "*",
            element: <ProtectedRoute 
              element={
                <div className="flex flex-col items-center justify-center min-h-screen">
                  <h1 className="text-4xl font-bold mb-4">404: Page Not Found</h1>
                  <p className="text-xl">The page you're looking for doesn't exist.</p>
                </div>
              } 
              allowedRoles={['admin', 'guest']} 
            />
          }
        ]
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]);
