// import React, { useState, useEffect } from 'react';
// import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
// import { db, auth } from '../firebase';
// import {
//   Box,
//   Button,
//   TextField,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Snackbar,
//   Alert,
//   Grid,
// } from '@mui/material';

// const AddVehicle = () => {
  // const [stations, setStations] = useState([]); // List of starting stations
  // const [routes, setRoutes] = useState([]); // List of routes for the selected station
  // const [vehicles, setVehicles] = useState([]); // List of vehicles
  // const [formData, setFormData] = useState({
  //   'Plate Number': '',
  //   'Seat Capacity': '',
  //   'Start Station': '',
  //   'Route': '',
  //   'Level': '',
  //   'Destination': '',
  //   'Price': '',
  //   'Net Price': '',
  //   'Service Charge': '',
  //   'distance_km': '',
  //   'Driver': 'Default Driver',
  //   'created_by': auth.currentUser?.email || 'Unknown User',
  //   'created_on': new Date().toISOString(),
  //   'status': 'Active',
  // });
  // const [showForm, setShowForm] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [successMessage, setSuccessMessage] = useState('');
  // const [error, setError] = useState('');

  // // Fetch stations on mount
  // useEffect(() => {
  //   const fetchStations = async () => {
  //     try {
  //       const stationsSnapshot = await getDocs(collection(db, 'Allroutes'));
  //       const stationsData = stationsSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         name: doc.id.replace(/-/g, ' '),
  //       }));
  //       setStations(stationsData);
  //     } catch (error) {
  //       setError('Failed to fetch starting stations.');
  //     }
  //   };

  //   fetchStations();
  // }, []);

  // // Fetch routes for the selected starting station
  // useEffect(() => {
  //   const fetchRoutesForStation = async () => {
  //     if (!formData['Start Station']) {
  //       setRoutes([]);
  //       return;
  //     }

  //     try {
  //       const routesSnapshot = await getDocs(
  //         collection(db, 'Allroutes', formData['Start Station'], 'routes')
  //       );
  //       const routesData = routesSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setRoutes(routesData);
  //     } catch (error) {
  //       setError('Failed to fetch routes for the selected starting station.');
  //     }
  //   };

  //   fetchRoutesForStation();
  // }, [formData['Start Station']]);

  // // Handle form input changes
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));

  //   if (name === 'Start Station') {
  //     // Reset dependent fields
  //     setFormData((prev) => ({
  //       ...prev,
  //       'Route': '',
  //       'Level': '',
  //       'Destination': '',
  //       'Price': '',
  //       'Net Price': '',
  //       'Service Charge': '',
  //       'distance_km': '',
  //     }));
  //   }

  //   if (name === 'Route') {
  //     const selectedRoute = routes.find((route) => route.route === value);
  //     if (selectedRoute) {
  //       setFormData((prev) => ({
  //         ...prev,
  //         'Level': selectedRoute.level,
  //         'Destination': selectedRoute.destination,
  //         'Price': selectedRoute.price,
  //         'Net Price': selectedRoute.netPrice,
  //         'Service Charge': selectedRoute.serviceCharge,
  //         'distance_km': selectedRoute.distanceKM,
  //       }));
  //     }
  //   }
  // };

  // // Save vehicle to the database
  // const saveVehicleToDatabase = async () => {
  //   setLoading(true);
  //   try {
  //     if (!formData['Plate Number']) {
  //       throw new Error('Plate Number is required.');
  //     }

  //     const vehicleRef = doc(db, 'Vehicles', formData['Plate Number']);
  //     await setDoc(vehicleRef, formData);

  //     setSuccessMessage('Vehicle saved successfully!');
  //     setShowForm(false);
  //     setFormData({
  //       'Plate Number': '',
  //       'Seat Capacity': '',
  //       'Start Station': '',
  //       'Route': '',
  //       'Level': '',
  //       'Destination': '',
  //       'Price': '',
  //       'Net Price': '',
  //       'Service Charge': '',
  //       'distance_km': '',
  //       'Driver': 'Default Driver',
  //       'created_by': auth.currentUser?.email || 'Unknown User',
  //       'created_on': new Date().toISOString(),
  //       'status': 'Active',
  //     });
  //   } catch (error) {
  //     setError(error.message || 'Failed to save vehicle.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

//   return (
//     <Box>
//       <Button variant="contained" onClick={() => setShowForm(true)}>
//         Add New Vehicle
//       </Button>

      // <Dialog open={showForm} onClose={() => setShowForm(false)} fullWidth>
      //   <DialogTitle>Add New Vehicle</DialogTitle>
      //   <DialogContent>
      //     <Grid container spacing={2}>
      //       <Grid item xs={12}>
      //         <TextField
      //           fullWidth
      //           label="Plate Number"
      //           name="Plate Number"
      //           value={formData['Plate Number']}
      //           onChange={handleInputChange}
      //           required
      //         />
      //       </Grid>
      //       <Grid item xs={12}>
      //         <TextField
      //           fullWidth
      //           label="Seat Capacity"
      //           name="Seat Capacity"
      //           type="number"
      //           value={formData['Seat Capacity']}
      //           onChange={handleInputChange}
      //           required
      //         />
      //       </Grid>
      //       <Grid item xs={12}>
      //         <TextField
      //           fullWidth
      //           select
      //           label="Start Station"
      //           name="Start Station"
      //           value={formData['Start Station'] || ''}
      //           onChange={handleInputChange}
      //           required
      //         >
      //           {stations.map((station) => (
      //             <MenuItem key={station.id} value={station.id}>
      //               {station.name}
      //             </MenuItem>
      //           ))}
      //         </TextField>
      //       </Grid>
      //       {formData['Start Station'] && (
      //         <Grid item xs={12}>
      //           <TextField
      //             fullWidth
      //             select
      //             label="Route"
      //             name="Route"
      //             value={formData['Route'] || ''}
      //             onChange={handleInputChange}
      //             required
      //           >
      //             {routes.map((route) => (
      //               <MenuItem key={route.id} value={route.route}>
      //                 {route.route}
      //               </MenuItem>
      //             ))}
      //           </TextField>
      //         </Grid>
      //       )}
      //     </Grid>
      //   </DialogContent>
      //   <DialogActions>
      //     <Button onClick={() => setShowForm(false)}>Cancel</Button>
      //     <Button variant="contained" disabled={loading} onClick={saveVehicleToDatabase}>
      //       Save Vehicle
      //     </Button>
      //   </DialogActions>
      // </Dialog>

      // <Snackbar
      //   open={!!successMessage}
      //   autoHideDuration={6000}
      //   onClose={() => setSuccessMessage('')}
      // >
      //   <Alert severity="success">{successMessage}</Alert>
      // </Snackbar>

      // <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
      //   <Alert severity="error">{error}</Alert>
      // </Snackbar>
//     </Box>
//   );
// };

// export default AddVehicle;




import React, { useState, useEffect } from 'react';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where,
  orderBy,
  serverTimestamp,
  setDoc, doc 
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { 
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  IconButton,
  Chip,
  Tooltip,
  Grid,
  Paper,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
  LinearProgress,
  MenuItem
} from '@mui/material';
import {
  DirectionsCar,
  Add,
  FilterList,
  Clear,
  Edit,
  Delete,
  LocationOn,
  Person,
  Route as RouteIcon,
  Speed,
  AttachMoney,
  LocalOffer,
  EventSeat,
  Phone,
  Home,
  Badge,
  ColorLens
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
    
    const AddVehicle = ({ workingRoute = "", routeData = [] }) => {
      const [vehicles, setVehicles] = useState([]);
      const [loading, setLoading] = useState(false);
      const [showForm, setShowForm] = useState(false);
      const [filters, setFilters] = useState({
        route: '',
        level: '',
        startStation: '',
        destination: '',
        plateNumber: ''
      });
 
    
      
    
      const [showFilters, setShowFilters] = useState(false);
      const [successMessage, setSuccessMessage] = useState("");
      const [error, setError] = useState("");
      const [stations, setStations] = useState([]); // List of starting stations
  const [routes, setRoutes] = useState([]); // List of routes for the selected station
 // List of vehicles
  const [formData, setFormData] = useState({
    'Plate Number': '',
    'Seat Capacity': '',
    'Start Station': '',
    'Route': '',
    'Level': '',
    'Destination': '',
    'Price': '',
    'Net Price': '',
    'Service Charge': '',
    'distance_km': '',
    'Driver': 'Default Driver',
    'created_by': auth.currentUser?.email || 'Unknown User',
    'created_on': new Date().toISOString(),
    'status': 'Active',
  });

  // Fetch stations on mount
  useEffect(() => {
    const fetchStations = async () => {
      try {
        const stationsSnapshot = await getDocs(collection(db, 'Allroutes'));
        const stationsData = stationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.id.replace(/-/g, ' '),
        }));
        setStations(stationsData);
      } catch (error) {
        setError('Failed to fetch starting stations.');
      }
    };

    fetchStations();
  }, []);

  // Fetch routes for the selected starting station
  useEffect(() => {
    const fetchRoutesForStation = async () => {
      if (!formData['Start Station']) {
        setRoutes([]);
        return;
      }

      try {
        const routesSnapshot = await getDocs(
          collection(db, 'Allroutes', formData['Start Station'], 'routes')
        );
        const routesData = routesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRoutes(routesData);
      } catch (error) {
        setError('Failed to fetch routes for the selected starting station.');
      }
    };

    fetchRoutesForStation();
  }, [formData['Start Station']]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'Start Station') {
      // Reset dependent fields
      setFormData((prev) => ({
        ...prev,
        'Route': '',
        'Level': '',
        'Destination': '',
        'Price': '',
        'Net Price': '',
        'Service Charge': '',
        'distance_km': '',
      }));
    }

    if (name === 'Route') {
      const selectedRoute = routes.find((route) => route.route === value);
      if (selectedRoute) {
        setFormData((prev) => ({
          ...prev,
          'Level': selectedRoute.level,
          'Destination': selectedRoute.destination,
          'Price': selectedRoute.price,
          'Net Price': selectedRoute.netPrice,
          'Service Charge': selectedRoute.serviceCharge,
          'distance_km': selectedRoute.distanceKM,
        }));
      }
    }
  };

  // Save vehicle to the database
  const saveVehicleToDatabase = async () => {
    setLoading(true);
    try {
      if (!formData['Plate Number']) {
        throw new Error('Plate Number is required.');
      }

      const vehicleRef = doc(db, 'cars', formData['Plate Number']);
      await setDoc(vehicleRef, formData);

      setSuccessMessage('Vehicle saved successfully!');
      setShowForm(false);
      setFormData({
        'Plate Number': '',
        'Seat Capacity': '',
        'Start Station': '',
        'Route': '',
        'Level': '',
        'Destination': '',
        'Price': '',
        'Net Price': '',
        'Service Charge': '',
        'distance_km': '',
        'Driver': 'Default Driver',
        'created_by': auth.currentUser?.email || 'Unknown User',
        'created_on': new Date().toISOString(),
        'status': 'Active',
      });
    } catch (error) {
      setError(error.message || 'Failed to save vehicle.');
    } finally {
      setLoading(false);
    }
  };

      // Fetch vehicles with filters
      const fetchVehicles = async () => {
        setLoading(true);
        try {
          let vehiclesQuery = collection(db, 'cars');
          
          // Apply filters
          if (filters.route) {
            vehiclesQuery = query(vehiclesQuery, where('Route', '==', filters.route));
          }
          if (filters.level) {
            vehiclesQuery = query(vehiclesQuery, where('Level', '==', filters.level));
          }
          if (filters.startStation) {
            vehiclesQuery = query(vehiclesQuery, where('Start Station', '==', filters.startStation));
          }
          if (filters.destination) {
            vehiclesQuery = query(vehiclesQuery, where('Destination', '==', filters.destination));
          }
          if (filters.plateNumber) {
            vehiclesQuery = query(vehiclesQuery, where('Plate Number', '==', filters.plateNumber));
          }
    
          const querySnapshot = await getDocs(vehiclesQuery);
          const vehiclesData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          setVehicles(vehiclesData);
        } catch (error) {
          console.error('Error fetching vehicles:', error);
          setError('Failed to fetch vehicles. Please try again.');
        } finally {
          setLoading(false);
        }
      };
    
      // Handle form input changes
    
    
      // Effect to fetch vehicles on mount and when filters change
      useEffect(() => {
        fetchVehicles();
      }, [filters]);
    
      // Form dialog content
   
      const columns = [
        {
          field: 'Plate Number',
          headerName: 'Plate Number',
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <DirectionsCar color="primary" />
              <Typography>{params.value}</Typography>
            </Box>
          )
        },
        {
          field: 'Driver',
          headerName: 'Driver',
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Person color="secondary" />
              <Typography>{params.value}</Typography>
            </Box>
          )
        },
        {
          field: 'Route',
          headerName: 'Route',
          flex: 1.5,
          renderCell: (params) => (
            <Chip
              icon={<RouteIcon />}
              label={params.value}
              color="primary"
              variant="outlined"
            />
          )
        },
        {
          field: 'Level',
          headerName: 'Level',
          width: 120,
          renderCell: (params) => (
            <Chip
              label={`Level ${params.value}`}
              color="secondary"
              size="small"
            />
          )
        },
        {
          field: 'Start Station',
          headerName: 'Start Station',
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOn color="success" />
              <Typography>{params.value}</Typography>
            </Box>
          )
        },
        {
          field: 'Destination',
          headerName: 'Destination',
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOn color="error" />
              <Typography>{params.value}</Typography>
            </Box>
          )
        },
        {
          field: 'Price',
          headerName: 'Price',
          width: 120,
          renderCell: (params) => (
            <Chip
              icon={<AttachMoney />}
              label={params.value}
              color="info"
              size="small"
            />
          )
        },
        {
          field: 'status',
          headerName: 'Status',
          width: 130,
          renderCell: (params) => (
            <Chip
              label={params.value}
              color={params.value === 'Active' ? 'success' : 'error'}
              size="small"
              sx={{ 
                background: params.value === 'Active' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(211, 47, 47, 0.1)',
                fontWeight: 'bold'
              }}
            />
          )
        },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 120,
          renderCell: (params) => (
            <Box>
              <Tooltip title="Edit">
                <IconButton size="small" color="primary">
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton size="small" color="error">
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }
      ];
    
      // Enhanced FilterBar component
      const FilterBar = () => (
        <Paper 
          sx={{ 
            p: 2, 
            mb: 2,
            background: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            borderRadius: 2,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Route"
                select
                value={filters.route}
                onChange={(e) => setFilters(prev => ({ ...prev, route: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RouteIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              >
                {routeData.map((route) => (
                  <MenuItem key={route.route} value={route.route}>{route.route}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Level"
                select
                value={filters.level}
                onChange={(e) => setFilters(prev => ({ ...prev, level: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Speed color="secondary" />
                    </InputAdornment>
                  ),
                }}
              >
                {['1', '2', '3'].map(level => (
                  <MenuItem key={level} value={level}>Level {level}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Start Station"
                value={filters.startStation}
                onChange={(e) => setFilters(prev => ({ ...prev, startStation: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color="success" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Destination"
                value={filters.destination}
                onChange={(e) => setFilters(prev => ({ ...prev, destination: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color="error" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Plate Number"
                value={filters.plateNumber}
                onChange={(e) => setFilters(prev => ({ ...prev, plateNumber: e.target.value }))}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DirectionsCar />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<Clear />}
                onClick={() => setFilters({
                  route: '',
                  level: '',
                  startStation: '',
                  destination: '',
                  plateNumber: ''
                })}
                sx={{ height: '40px' }}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </Paper>
      );
    
    
      return (
        <Box sx={{ p: 3 }}>
          {/* Header Card */}
          <Card 
        sx={{ 
          mb: 3, 
          background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
        }}
      >
        <CardContent>
          <Typography variant="h4" sx={{ color: 'white', mb: 1, fontWeight: 600 }}>
            Vehicle Management System
          </Typography>
          <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            Managing {vehicles.length} vehicles in your fleet
          </Typography>
        </CardContent>
      </Card>

          {/* Action Buttons */}
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
          <Button variant="contained" onClick={() => setShowForm(true)}>
          Add New Vehicle
        </Button>
        <Dialog open={showForm} onClose={() => setShowForm(false)} fullWidth>
        <DialogTitle>Add New Vehicle</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Plate Number"
                name="Plate Number"
                value={formData['Plate Number']}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Seat Capacity"
                name="Seat Capacity"
                type="number"
                value={formData['Seat Capacity']}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Start Station"
                name="Start Station"
                value={formData['Start Station'] || ''}
                onChange={handleInputChange}
                required
              >
                {stations.map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    {station.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formData['Start Station'] && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Route"
                  name="Route"
                  value={formData['Route'] || ''}
                  onChange={handleInputChange}
                  required
                >
                  {routes.map((route) => (
                    <MenuItem key={route.id} value={route.route}>
                      {route.route}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
          <Button variant="contained" disabled={loading} onClick={saveVehicleToDatabase}>
            Save Vehicle
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
      >
        <Alert severity="success">{successMessage}</Alert>
      </Snackbar>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
        <Button
          variant="outlined"
          startIcon={<FilterList />}
          onClick={() => setShowFilters(!showFilters)}
          sx={{
            borderColor: '#2196f3',
            color: '#2196f3',
            '&:hover': {
              borderColor: '#1976d2',
              background: 'rgba(33, 150, 243, 0.08)'
            }
          }}
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </Button>
      </Box>

      {showFilters && <FilterBar />}

    

    
      
          {loading ? (
            <LinearProgress sx={{ mb: 2 }} />
          ) : (
            <DataGrid
              rows={vehicles}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50]}
              checkboxSelection
              disableSelectionOnClick
              autoHeight
              sx={{
                '& .MuiDataGrid-cell': { fontSize: '0.9rem' },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#f5f5f5',
                  fontWeight: 'bold'
                }
              }}
            />
          )}
    
          {/* Add/Edit Form Dialog */}
          {/* <Dialog 
            open={showForm} 
            onClose={() => setShowForm(false)} 
            maxWidth="md" 
            fullWidth
          >
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DirectionsCar />
                Add New Vehicle
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              {renderFormContent()}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowForm(false)}>Cancel</Button>
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleSaveVehicle}
                disabled={loading}
              >
                Save Vehicle
              </Button>
            </DialogActions>
          </Dialog> */}
    
          {/* Notifications */}
          <Snackbar
            open={!!successMessage}
            autoHideDuration={6000}
            onClose={() => setSuccessMessage("")}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
    
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError("")}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        </Box>
      );
    };
    
    export default AddVehicle;


