// import {
//   Box,
//   Button,
//   Checkbox,
//   CircularProgress,
//   FormControlLabel,
//   FormGroup,
//   Stack,
//   TextField,
//   Typography,
//   circularProgressClasses,
//   colors,
// } from "@mui/material";
// import React, { useState, useEffect } from "react";

// import { images } from "../assets";
// import { Link, useNavigate } from "react-router-dom";
// import Animate from "../components/common/Animate";
// import { auth } from "../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { InputAdornment, IconButton } from "@mui/material";
// import { VisibilityOff, Visibility } from "@mui/icons-material";

// const LoginPage = () => {
//   const navigate = useNavigate();

//   const [onRequest, setOnRequest] = useState(false);
//   const [loginProgress, setLoginProgress] = useState(0);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [usernameError, setUsernameError] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const [generalError, setGeneralError] = useState("");
//   const [rememberMe, setRememberMe] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);

//   useEffect(() => {
//     const rememberMeValue = localStorage.getItem("rememberMe") === "true";
//     setRememberMe(rememberMeValue);
//   }, []);

//   const handleRememberMeChange = (event) => {
//     setRememberMe(event.target.checked);
//     localStorage.setItem("rememberMe", event.target.checked);
//   };

//   const handlePasswordVisibility = () => {
//     setShowPassword((prev) => !prev);
//   };
//   const onSignin = async (e) => {
//     e.preventDefault();
  
//     const email = e.target.elements.email.value;
//     const password = e.target.elements.password.value;
  
//     if (!email || !/\S+@\S+\.\S+/.test(email)) {
//       setUsernameError('Valid email is required');
//       setOnRequest(false);
//       return;
//     }
  
//     if (!password) {
//       setPasswordError('Password is required');
//       setOnRequest(false);
//       return;
//     }
  
//     setOnRequest(true);
  
//     try {
//       const userCredential = await signInWithEmailAndPassword(auth, email, password);
//       const user = userCredential.user;
//       setUsernameError('');
//       setPasswordError('');
//       setGeneralError('');
//       setIsLoggedIn(true);
//       navigate("/dashboard"); // Navigate to dashboard page here
//     } catch (error) {
//       const errorCode = error.code;
//       const errorMessage = error.message;
  
//       if (errorCode === 'auth/invalid-email') {
//         setUsernameError(errorMessage);
//       } else if (errorCode === 'auth/wrong-password') {
//         setPasswordError(errorMessage);
//       } else if (errorCode === 'auth/invalid-credential') {
//         setGeneralError(errorMessage);
//       } else {
//         alert(`Error: ${errorMessage}`);
//       }
//       console.error(`Error code: ${errorCode}, Error message: ${errorMessage}`);
//       setOnRequest(false);
//     }
  
//     const interval = setInterval(() => {
//       setLoginProgress(prev => prev + 100 / 40);
//     }, 50);
  
//     setTimeout(() => {
//       clearInterval(interval);
//     }, 2000);
//   };
  

//   return (
//     <Box
//       position="relative"
//       height="100vh"
//       sx={{ "::-webkit-scrollbar": { display: "none" } }}
//     >
//       <Box
//         sx={{
//           position: "absolute",
//           right: 0,
//           height: "80%",
//           width: "40%",
//           backgroundPosition: "center",
//           backgroundSize: "cover",
//           backgroundRepeat: "no-repeat",
//           backgroundImage: `url(${images.loginBg})`,
//         }}
//       />
//       <Box
//         sx={{
//           position: "absolute",
//           left: 0,
//           height: "100%",
//           width: isLoggedIn
//             ? "100%"
//             : { xl: "30%", lg: "40%", md: "50%", xs: "100%" },
//           transition: "all 1s ease-in-out",
//           bgcolor: colors.common.white,
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//             opacity: isLoggedIn ? 0 : 1,
//             transition: "all 0.3s ease-in-out",
//             height: "100%",
//             "::-webkit-scrollbar": { display: "none" },
//           }}
//         >
//           <Box sx={{ textAlign: "center", p: 5 }}>
//             <Animate type="fade" delay={0.5}>
//               <img src={images.logo} alt="logo" height={100}></img>
//             </Animate>
//           </Box>
//           <Box
//             sx={{
//               position: "absolute",
//               top: 0,
//               left: 0,
//               width: "100%",
//               height: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               "::-webkit-scrollbar": { display: "none" },
//             }}
//           >
//             <Animate type="fade" sx={{ maxWidth: 400, width: "100%" }}>
//               <Box
//                 component="form"
//                 maxWidth={400}
//                 width="100%"
//                 onSubmit={onSignin}
//               >
//                 <Stack spacing={3}>
//                   <TextField
//                     name="email"
//                     label="Email"
//                     type="email"
//                     fullWidth
//                   />
//                   {usernameError && (
//                     <Typography color="error">{usernameError}</Typography>
//                   )}
//                   <TextField
//                     name="password"
//                     label="Password"
//                     type={showPassword ? "text" : "password"}
                   
//                     fullWidth
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton onClick={handlePasswordVisibility} edge="end">
//                             {showPassword ? <VisibilityOff /> : <Visibility />}
//                           </IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                   {passwordError && (
//                     <Typography color="error">{passwordError}</Typography>
//                   )}
//                   <Button
//                     type="submit"
//                     size="large"
//                     variant="contained"
//                     color="success"
//                   >
//                     Sign In
//                   </Button>
//                   {generalError && (
//                     <Typography color="error">{generalError}</Typography>
//                   )}
//                   <Stack
//         direction="row"
//         justifyContent="space-between"
//         alignItems="center"
//       >
//         <FormGroup>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={rememberMe}
//                 onChange={handleRememberMeChange}
//               />
//             }
//             label="Remember me"
//           />
//         </FormGroup>
//         <Typography color="error" fontWeight="bold">
//           <Link to="/PasswordResetPage">Forgot password?</Link>
//         </Typography>
//       </Stack>
//                 </Stack>
//               </Box>
//             </Animate>
//           </Box>
//           {/* <Box sx={{ textAlign: "center", p: 5, zIndex: 2 }}>
//             <Animate type="fade" delay={1}>
//               <Typography
//                 display="inline"
//                 fontWeight="bold"
//                 sx={{ "& > a": { color: colors.red[900], ml: "5px" } }}
//               >
//                 Don't have an account -<Link to="#">Register now</Link>
//               </Typography>
//             </Animate>
//           </Box> */}
//           {onRequest && (
//             <Stack
//               alignItems="center"
//               justifyContent="center"
//               sx={{
//                 height: "100%",
//                 width: "100%",
//                 position: "absolute",
//                 top: 0,
//                 left: 0,
//                 bgcolor: colors.common.white,
//                 zIndex: 1000,
//               }}
//             >
//               <Box position="relative">
//                 <CircularProgress
//                   variant="determinate"
//                   sx={{ color: colors.grey[200] }}
//                   size={100}
//                   value={100}
//                 />
//                 <CircularProgress
//                   variant="determinate"
//                   disableShrink
//                   value={loginProgress}
//                   size={100}
//                   sx={{
//                     [`& .${circularProgressClasses.circle}`]: {
//                       strokeLinecap: "round",
//                     },
//                     position: "absolute",
//                     left: 0,
//                     color: colors.green[600],
//                   }}
//                 />
//               </Box>
//             </Stack>
//           )}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default LoginPage;
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { images } from "../assets";

const LoginPage = () => {
  const navigate = useNavigate();
  const [onRequest, setOnRequest] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe") === "true";
    setRememberMe(rememberMeValue);
  }, []);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    localStorage.setItem("rememberMe", event.target.checked);
  };

  const handlePasswordVisibility = () => setShowPassword((prev) => !prev);

  const onSignin = async (e) => {
    e.preventDefault();

    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setUsernameError("Valid email is required");
      setOnRequest(false);
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      setOnRequest(false);
      return;
    }

    setOnRequest(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setUsernameError("");
      setPasswordError("");
      setGeneralError("");
      navigate("/dashboard");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === "auth/invalid-email") setUsernameError(errorMessage);
      else if (errorCode === "auth/wrong-password") setPasswordError(errorMessage);
      else setGeneralError(errorMessage);

      setOnRequest(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        background: `linear-gradient(135deg, #ff7eb3, #ff758c, #ffae58, #ffd056)`
      }}
    >
      {/* Decorative Background Icon */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          backgroundImage: `url(${images.backgroundPattern})`,
          opacity: 0.1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />

      <Box
        sx={{
          zIndex: 2,
          width: "90%",
          maxWidth: 400,
          p: 5,
          bgcolor: "white",
          borderRadius: 4,
          boxShadow: 3,
          textAlign: "center",
        }}
        component="form"
        onSubmit={onSignin}
      >
        <img src={images.logo} alt="Logo" height={60} style={{ marginBottom: 20 }} />
        <Typography variant="h5" fontWeight="bold" mb={3}>
          Welcome Back
        </Typography>
        <Stack spacing={2}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            error={!!usernameError}
            helperText={usernameError}
          />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    sx={{
                      color: "#6a11cb",
                      "&.Mui-checked": { color: "#2575fc" },
                    }}
                  />
                }
                label="Remember me"
              />
            </FormGroup>
            <Typography variant="body2">
              <Link to="/PasswordResetPage" style={{ color: "#2575fc" }}>
                Forgot password?
              </Link>
            </Typography>
          </Stack>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              background: `linear-gradient(to right, #6a11cb, #2575fc)`,
              color: "white",
              fontWeight: "bold",
              "&:hover": { background: `linear-gradient(to right, #2575fc, #6a11cb)` },
            }}
          >
            Sign In
          </Button>
          {generalError && <Typography color="error">{generalError}</Typography>}
        </Stack>
        {onRequest && (
          <CircularProgress
            sx={{
              mt: 3,
              color: "#6a11cb",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default LoginPage;

