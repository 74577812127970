import React, { useState, useEffect } from "react";
import AccessTime from '@mui/icons-material/AccessTime';
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Avatar,
  Card,
  CardContent,
  Chip,
  InputAdornment,
  Grid,
  Tooltip,
  Alert,
  Snackbar
} from "@mui/material";
import {
  Add,
  Search,
  FilterList,
  Edit,
  Delete,
  Email,
  Phone,
  LocationOn,
  Badge,
  CloudUpload,
  Clear,
  AdminPanelSettings,
  SupervisorAccount,
  LocalShipping,
  Group
} from "@mui/icons-material";
import { doc, setDoc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage, db } from "../firebase";

const AddUserAndVehicle = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    userType: "all",
    workingRoute: "all"
  });
  const [image, setImage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [formData, setFormData] = useState({
    code: "",
    userType: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    workingRoute: ""
  });

  const userTypes = ["admin", "sales", "supervisor"];
  const routes = [
    "Modjo", "Sebeta", "Alemgena", "Leman", "Welliso",
    "Cheffe_Donsa", "Tajji", "Busa", "Sululta", "Burayu", "sadeen"
  ];

  useEffect(() => {
    fetchUsers();
  }, []);
  const uploadImage = async (file) => {
    if (!file) return;
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "peoples"));
      const userData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          registration_time: data.registration_time?.toDate
            ? data.registration_time.toDate().toISOString() // If Timestamp
            : data.registration_time // If ISO string
        };
      });
      setUsers(userData);
    } catch (error) {
      showSnackbar("Error fetching users", "error");
    }
    setLoading(false);
  };
  

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredUsers = users.filter(user => {
    const matchesSearch = user.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         user.phone_number?.includes(searchTerm);
    
    const matchesUserType = selectedFilters.userType === "all" || user.userType === selectedFilters.userType;
    const matchesRoute = selectedFilters.workingRoute === "all" || user.working_route === selectedFilters.workingRoute;
    
    return matchesSearch && matchesUserType && matchesRoute;
  });

  const handleDialogOpen = (user = null) => {
    if (user) {
      setIsEditing(true);
      setFormData({
        code: user.id,
        userType: user.userType,
        fullName: user.full_name,
        email: user.email,
        phoneNumber: user.phone_number,
        workingRoute: user.working_route
      });
      setImage(user.imageUrl || null);
    } else {
      setIsEditing(false);
      setFormData({
        code: "",
        userType: "",
        fullName: "",
        email: "",
        phoneNumber: "",
        workingRoute: ""
      });
      setImage(null);
    }
    setOpenDialog(true);
  };

  const handleSaveUser = async () => {
    try {
      const currentTime = new Date().toISOString();
      let imageUrl = "";
      
      if (image instanceof File) {
        imageUrl = await uploadImage(image, formData.userType);
      }

      const userData = {
        code: formData.code.trim(),
        userType: formData.userType.trim(),
        full_name: formData.fullName.trim(),
        email: formData.email.trim(),
        phone_number: formData.phoneNumber.trim(),
        working_route: formData.workingRoute.trim(),
        registration_time: currentTime,
        ...(imageUrl && { imageUrl })
      };

      await setDoc(doc(db, "peoples", formData.code), userData);
      await setDoc(doc(db, "userss", formData.code), {
        registration_time: currentTime,
        userType: formData.userType
      });

      showSnackbar(`User successfully ${isEditing ? 'updated' : 'added'}`);
      fetchUsers();
      setOpenDialog(false);
    } catch (error) {
      showSnackbar("Error saving user", "error");
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteDoc(doc(db, "peoples", id));
      await deleteDoc(doc(db, "userss", id));
      showSnackbar("User successfully deleted");
      fetchUsers();
    } catch (error) {
      showSnackbar("Error deleting user", "error");
    }
  };

  const getUserTypeIcon = (type) => {
    switch (type) {
      case 'admin':
        return <AdminPanelSettings />;
      case 'sales':
        return <LocalShipping />;
      case 'supervisor':
        return <SupervisorAccount />;
      default:
        return <Badge />;
    }
  };

  const getCountByUserType = (type) => users.filter(user => user.userType === type).length;


  return (
    <Box sx={{ bgcolor: '#f5f7fa', minHeight: '100vh', p: 3 }}>
      {/* Header Section */}
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 3,
          borderRadius: 2,
          background: 'linear-gradient(135deg, #1976d2 0%, #1565c0 100%)',
          color: 'white'
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold">
              User Management
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => handleDialogOpen()}
              sx={{
                bgcolor: 'white',
                color: '#1976d2',
                '&:hover': {
                  bgcolor: '#e3f2fd'
                }
              }}
            >
              Add New User
            </Button>
          </Grid>
        </Grid>
      </Paper>
{/* Summary Section */}
  <Typography variant="h6" sx={{ mb: 2 }}>Total Users Summary</Typography>
<Grid container spacing={2} mb={3}>
  
        {[
          { title: "Total Users", count: users.length, icon: <Group />, color: "primary" },
          { title: "Admins", count: getCountByUserType("admin"), icon: <AdminPanelSettings />, color: "secondary" },
          { title: "Sales", count: getCountByUserType("sales"), icon: <LocalShipping />, color: "info" },
          { title: "Supervisors", count: getCountByUserType("supervisor"), icon: <SupervisorAccount />, color: "warning" }
        ].map(({ title, count, icon, color }) => (
          <Grid item xs={12} sm={6} md={3} key={title}>
            <Card
              elevation={2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                bgcolor: `${color}.light`,
                borderRadius: 2
              }}
            >
              <Avatar sx={{ bgcolor: `${color}.main`, mr: 2 }}>{icon}</Avatar>
              <Box>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="h4" fontWeight="bold">{count}</Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* Filters Section */}
      <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchTerm("")}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {userTypes.map((type) => (
                <Chip
                  key={type}
                  label={type.charAt(0).toUpperCase() + type.slice(1)}
                  icon={getUserTypeIcon(type)}
                  onClick={() => setSelectedFilters(prev => ({
                    ...prev,
                    userType: prev.userType === type ? 'all' : type
                  }))}
                  color={selectedFilters.userType === type ? "primary" : "default"}
                  sx={{ m: 0.5 }}
                />
              ))}
              {routes.map((route) => (
                <Chip
                  key={route}
                  label={route}
                  icon={<LocationOn />}
                  onClick={() => setSelectedFilters(prev => ({
                    ...prev,
                    workingRoute: prev.workingRoute === route ? 'all' : route
                  }))}
                  color={selectedFilters.workingRoute === route ? "primary" : "default"}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Users Grid */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredUsers.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user.id}>
              <Card 
                elevation={0}
                sx={{
                  borderRadius: 2,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={user.imageUrl}
                      sx={{ width: 56, height: 56, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="h6" fontWeight="bold">
                        {user.full_name}
                      </Typography>
                      <Chip
                        size="small"
                        icon={getUserTypeIcon(user.userType)}
                        label={user.userType}
                        color="primary"
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
  <Typography
    variant="body2"
    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
  >
    <Email fontSize="small" color="action" />
    {user.email}
  </Typography>
  <Typography
    variant="body2"
    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
  >
    <Phone fontSize="small" color="action" />
    {user.phone_number}
  </Typography>
  <Typography
  variant="body2"
  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
>
  <Badge fontSize="small" color="action" />
  Saved on:{" "}
  {user.registration_time && !isNaN(new Date(user.registration_time).getTime())
    ? new Intl.DateTimeFormat("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(new Date(user.registration_time))
    : "Invalid Date"}
</Typography>

</Box>


                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
                    <Tooltip title="Edit User">
                      <IconButton
                        size="small"
                        onClick={() => handleDialogOpen(user)}
                        sx={{ color: 'primary.main' }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteUser(user.id)}
                        sx={{ color: 'error.main' }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Add/Edit User Dialog */}
      <Dialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? 'Edit User' : 'Add New User'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Code"
                name="code"
                value={formData.code}
                onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                disabled={isEditing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="User Type"
                name="userType"
                value={formData.userType}
                onChange={(e) => setFormData({ ...formData, userType: e.target.value })}
                SelectProps={{
                  native: true
                }}
              >
                <option value="">Select Type</option>
                {userTypes.map((type) => (
                  <option key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                label="Working Route"
                name="workingRoute"
                value={formData.workingRoute}
                onChange={(e) => setFormData({ ...formData, workingRoute: e.target.value })}
                SelectProps={{
                  native: true
                }}
              >
                <option value="">Select Route</option>
                {routes.map((route) => (
                  <option key={route} value={route}>
                    {route}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              />
            </Grid>
         
            <Grid item xs={12}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUpload />}
                sx={{ width: '100%', height: '100px' }}
              >
                Upload Profile Image
                <input
                  type="file"
                  hidden
                  onChange={(e) => setImage(e.target.files[0])}
                  accept="image/*"
                />
              </Button>
            </Grid>
            {image && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: '100%',
                    height: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px dashed grey',
                    borderRadius: 1,
                    position: 'relative'
                  }}
                >
                  <img
                    src={image instanceof File ? URL.createObjectURL(image) : image}
                    alt="Preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain'
                    }}
                  />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      bgcolor: 'background.paper'
                    }}
                    onClick={() => setImage(null)}
                  >
                    <Clear />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={handleSaveUser}
            variant="contained"
            disabled={!formData.code || !formData.userType || !formData.fullName}
          >
            {isEditing ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          elevation={6}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddUserAndVehicle;
