import { useRouteError } from "react-router-dom";
import { isFirebaseError } from "../routes/index";

const ErrorPage = () => {
  const error = useRouteError();

  // Handle Firebase offline error
  if (isFirebaseError(error) && error.message.includes("client is offline")) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <div className="p-8 bg-white rounded-lg shadow-md">
          <h1 className="text-4xl font-bold mb-4 text-red-600">
            No Internet Connection
          </h1>
          <p className="text-xl mb-4">
            Please check your internet connection and try again.
          </p>
          <button 
            onClick={() => window.location.reload()} 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Retry Connection
          </button>
        </div>
      </div>
    );
  }

  // Handle other errors
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-4">Oops!</h1>
        <p className="text-xl mb-4">Sorry, an unexpected error has occurred.</p>
        <p className="text-gray-600">
          {error.statusText || error.message || "Unknown error"}
        </p>
      </div>
    </div>
  );
};

export default ErrorPage; 
