import { Box, LinearProgress, Stack, Typography, colors, linearProgressClasses } from '@mui/material';
import React, { useEffect, useState } from "react";
import MPaper from './MPaper';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import the Firestore instance

const initialBookedData = [
  {
    title: "Modjo",
    value: "Loading...",
    percent: 0,
    color: colors.yellow[600]
  },
  {
    title: "Sebeta",
    value: "Loading...",
    percent: 0,
    color: colors.red[600]
  },
  {
    title: "Alemgena",
    value: "Loading...",
    percent: 0,
    color: colors.green[600]
  },
  {
    title: "Cheffe-Donsa",
    value: "Loading...",
    percent: 0,
    color: colors.blue[600]
  },
  {
    title: "Lemmen",
    value: "Loading...",
    percent: 0,
    color: colors.purple[600]
  },
  {
    title: "Welliso",
    value: "Loading...",
    percent: 0,
    color: colors.orange[600]
  },
  {
    title: "Tajji",
    value: "Loading...",
    percent: 0,
    color: colors.grey[600]
  },
  {
    title: "Busa",
    value: "Loading...",
    percent: 0,
    color: colors.teal[600]
  },
  {
    title: "Sadeen",
    value: "Loading...",
    percent: 0,
    color: colors.indigo[600]
  },
  {
    title: "Sululta",
    value: "Loading...",
    percent: 0,
    color: colors.lime[600]
  },
  {
    title: "Burayu",
    value: "Loading...",
    percent: 0,
    color: colors.cyan[600]
  }
];

// Recursive function to get the total charge from nested objects
const getTotalCharge = (obj) => {
  let total = 0;
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object') {
          total += getTotalCharge(value);
        } else if (key === 'Total Charge') {
          const charge = parseFloat(value);
          if (!isNaN(charge)) {
            total += charge;
          }
        }
      }
    }
  }
  return total;
};

const BookedData = () => {
  const [bookedData, setBookedData] = useState(initialBookedData);

  useEffect(() => {
    const fetchData = async () => {
      const collections = {
        tickets_getout: "Modjo",
        tickets_getout_Alemgena: "Alemgena",
        tickets_getout_sebeta: "Sebeta",
        tickets_getout_CheffeDonsa: "Cheffe-Donsa",
        tickets_getout_leman: "Lemmen",
        tickets_getout_welliso: "Welliso",
        tickets_getout_Tajji: "Tajji",
        tickets_getout_busa: "Busa",
        tickets_getout_sadeen: "Sadeen",
        tickets_getout_Sululta: "Sululta",
        tickets_getout_Burayu: "Burayu",
      };

      const totalServiceCharges = {
        Modjo: 0,
        Alemgena: 0,
        Sebeta: 0,
        "Cheffe-Donsa": 0,
        Lemmen: 0,
        Welliso: 0,
        Tajji: 0,
        Busa: 0,
        Sadeen: 0,
        Sululta: 0,
        Burayu: 0,
      };

      const totalTickets = {
        Modjo: 0,
        Alemgena: 0,
        Sebeta: 0,
        "Cheffe-Donsa": 0,
        Lemmen: 0,
        Welliso: 0,
        Tajji: 0,
        Busa: 0,
        Sadeen: 0,
        Sululta: 0,
        Burayu: 0,
      };

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      const todayString = yyyy + "-" + mm + "-" + dd;

      for (const [collectionName, route] of Object.entries(collections)) {
        const docRef = doc(db, collectionName, todayString);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          Object.values(data).forEach((nestedData) => {
            if (nestedData) {
              const charge = getTotalCharge(nestedData);
              totalServiceCharges[route] += charge;
              
              // Counting tickets (assuming each nested object represents a ticket)
              totalTickets[route] += 1;
            }
          });
        }
      }

      const totalServiceCharge = Object.values(totalServiceCharges).reduce((acc, charge) => acc + charge, 0);

      const updatedBookedData = Object.entries(totalServiceCharges).map(([route, charge], index) => {
        const colorMapping = {
          Modjo: colors.yellow[600],
          Sebeta: colors.red[600],
          Alemgena: colors.green[600],
          "Cheffe-Donsa": colors.blue[600],
          Lemmen: colors.purple[600],
          Welliso: colors.orange[600],
          Tajji: colors.grey[600],
          Busa: colors.teal[600], 
          Sadeen: colors.indigo[600],
          Sululta: colors.lime[600],
          Burayu: colors.cyan[600],

        };

        return {
          title: route,
          value: `${charge.toFixed(2)} Birr (${totalTickets[route]} Tickets)`,
          percent: (charge / totalServiceCharge) * 100 || 0,
          color: colorMapping[route] || colors.grey[600]
        };
      });

      setBookedData(updatedBookedData);
    };

    fetchData();
  }, []);

  return (
    <MPaper title="Stations" fullHeight>
      <Stack spacing={4}>
        {bookedData.map((data, index) => (
          <Stack spacing={1} key={index}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" fontWeight="600">{data.title}</Typography>
              <Typography variant="caption" fontWeight="600">{data.value}</Typography>
            </Stack>
            <Box>
              <LinearProgress
                variant="determinate"
                value={data.percent}
                sx={{
                  bgcolor: colors.grey[200],
                  height: 10,
                  borderRadius: 5,
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    bgcolor: data.color
                  }
                }}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </MPaper>
  );
};

export default BookedData;
