import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Paper, Stack, Typography, colors } from '@mui/material';
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import the Firestore instance

const chartData = {
  labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Revenue",
      data: [301, 301, 302, 303, 302, 301, 302, 303],
      borderColor: colors.green[600],
      tension: 0.5
    }
  ]
};

// Recursive function to get the total charge from nested objects
const getTotalCharge = (obj) => {
  let total = 0;
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'object') {
          total += getTotalCharge(value);
        } else if (key === 'Total Charge') {
          const charge = parseFloat(value);
          if (!isNaN(charge)) {
            total += charge;
          }
        }
      }
    }
  }
  return total;
};

const TotalIncome = () => {
  const [totalIncome, setTotalIncome] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const collections = {
        tickets_getout: "Modjo",
        tickets_getout_Alemgena: "Alemgena",
        tickets_getout_sebeta: "Sebeta",
        tickets_getout_CheffeDonsa: "Cheffe-Donsa",
        tickets_getout_leman: "Lemmen",
        tickets_getout_welliso: "Welliso",
        tickets_getout_Tajji: "Tajji",
        tickets_getout_busa: "Busa",
        tickets_getout_sadeen: "Sadeen",
        tickets_getout_Sululta: "Sululta",
        tickets_getout_Burayu: "Burayu",
      };

      let currentMonthIncome = 0;

      const today = new Date();
      const currentMonth = today.getMonth() + 1; // getMonth() returns 0-based month
      const currentYear = today.getFullYear();

      const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

      for (const [collectionName, route] of Object.entries(collections)) {
        for (let day = 1; day <= daysInMonth; day++) {
          const dayString = day.toString().padStart(2, '0');
          const monthString = currentMonth.toString().padStart(2, '0');

          const docRef = doc(db, collectionName, `${currentYear}-${monthString}-${dayString}`);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            currentMonthIncome += getTotalCharge(data);
          }
        }
      }

      setTotalIncome(currentMonthIncome);

      // Calculate the percentage change compared to the previous month
      let previousMonthIncome = 0;

      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      const daysInPreviousMonth = new Date(previousYear, previousMonth, 0).getDate();

      for (const [collectionName, route] of Object.entries(collections)) {
        for (let day = 1; day <= daysInPreviousMonth; day++) {
          const dayString = day.toString().padStart(2, '0');
          const monthString = previousMonth.toString().padStart(2, '0');

          const docRef = doc(db, collectionName, `${previousYear}-${monthString}-${dayString}`);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            previousMonthIncome += getTotalCharge(data);
          }
        }
      }

      const change = previousMonthIncome === 0 ? 0 : ((currentMonthIncome - previousMonthIncome) / previousMonthIncome) * 100;
      setPercentageChange(change);
    };

    fetchData();
  }, []);

  return (
    <Paper elevation={0} sx={{
      p: 3,
      background: "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
      color: colors.green[800],
      height: "100%"
    }}>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={2}>
            <Typography variant="body2" fontWeight="bold">Total Sales This Month</Typography>
            <Typography variant="h4" fontWeight="bold">{totalIncome.toFixed(2)}</Typography>
          </Stack>
          <Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <TrendingUpOutlinedIcon fontSize="small" />
              <Typography variant="body2" fontWeight="bold">
                {percentageChange.toFixed(1)}%
              </Typography>
            </Stack>
            <Typography variant="subtitle2" fontWeight={400}>
              than last month
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Line
            data={chartData}
            height="100px"
            options={{
              responsive: true,
              maintainAspectRatio: true,
              scales: {
                x: { display: false },
                y: { display: false }
              },
              elements: { point: { radius: 0 } },
              plugins: { legend: { display: false } }
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default TotalIncome;
