import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  IconButton,
  Chip,
  CircularProgress,
  Paper,
} from '@mui/material';
import {
  Person,
  PersonOff,
  AdminPanelSettings,
  SupervisorAccount,
  Person as PersonIcon,
  Search,
  FilterAlt,
  FilterAltOff,
  Route,
  CalendarToday
} from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const parseTimestamp = (timestamp) => {
  if (!timestamp) return null;
  return dayjs(timestamp.toDate ? timestamp.toDate() : timestamp);
};

const isSameDay = (date1, date2) => {
  if (!date1 || !date2) return false;
  return dayjs(date1).format('YYYY-MM-DD') === dayjs(date2).format('YYYY-MM-DD');
};

const ActiveSalesPage = () => {
  const [users, setUsers] = useState({
    online: [],
    offline: []
  });
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filters, setFilters] = useState({
    status: 'all',
    userType: 'all',
    workingRoute: 'all',
    search: ''
  });
  const [adminCount, setAdminCount] = useState(0);
  const [supervisorCount, setSupervisorCount] = useState(0);
  const [salesCount, setSalesCount] = useState(0);
  const [availableRoutes, setAvailableRoutes] = useState([]);

  const processUserActivities = (activitiesData, targetDate) => {
    let online = [];
    let offline = [];
    let admin = 0;
    let supervisor = 0;
    let sales = 0;
    let routes = new Set();

    activitiesData.forEach((userActivity) => {
      const { loginHistory = [], logoutHistory = [], status, userType, workingRoute } = userActivity;
      
      // Filter login/logout history for the selected date
      const dayLogins = loginHistory.filter(login => 
        isSameDay(parseTimestamp(login.loginTime), targetDate)
      );
      const dayLogouts = logoutHistory.filter(logout => 
        isSameDay(parseTimestamp(logout.logoutTime), targetDate)
      );

      // Get all logins for the day sorted by time
      const sortedDayLogins = dayLogins.sort((a, b) => 
        parseTimestamp(a.loginTime).diff(parseTimestamp(b.loginTime))
      );

      // Get all logouts for the day sorted by time
      const sortedDayLogouts = dayLogouts.sort((a, b) => 
        parseTimestamp(a.logoutTime).diff(parseTimestamp(b.logoutTime))
      );

      const firstLogin = sortedDayLogins[0];
      const lastLogout = sortedDayLogouts[sortedDayLogouts.length - 1];
      
      const userTypeValue = firstLogin?.userType || userType || "N/A";
      const workingRouteValue = firstLogin?.workingRoute || workingRoute || "Unknown";

      // Add route to available routes
      if (workingRouteValue !== "Unknown") {
        routes.add(workingRouteValue);
      }

      // Skip if no activity on selected date and it's not today
      if (dayLogins.length === 0 && dayLogouts.length === 0 && !isSameDay(targetDate, dayjs())) {
        return;
      }

      const userData = {
        userCode: userActivity.userCode,
        userType: userTypeValue,
        workingRoute: workingRouteValue,
        loginTime: firstLogin
          ? parseTimestamp(firstLogin.loginTime).format("YYYY-MM-DD hh:mm:ss A")
          : "N/A",
        loginCount: dayLogins.length,
        logoutCount: dayLogouts.length,
        status: status || "offline"
      };

      // Determine if user is currently online
      const isCurrentlyOnline = isSameDay(dayjs(), targetDate) && 
        status === "online" &&
        (!lastLogout || (firstLogin && parseTimestamp(firstLogin.loginTime).isAfter(parseTimestamp(lastLogout.logoutTime))));

      if (isCurrentlyOnline) {
        online.push(userData);
      } else {
        userData.logoutTime = lastLogout
          ? parseTimestamp(lastLogout.logoutTime).format("YYYY-MM-DD hh:mm:ss A")
          : "N/A";
        offline.push(userData);
      }

      // Count user types
      if (userTypeValue.toLowerCase() === "admin") admin++;
      if (userTypeValue.toLowerCase() === "supervisor") supervisor++;
      if (userTypeValue.toLowerCase() === "sales") sales++;
    });

    return { online, offline, admin, supervisor, sales, routes: Array.from(routes) };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const activitiesSnapshot = await getDocs(collection(db, "activities"));
        const activitiesData = activitiesSnapshot.docs.map((doc) => ({
          userCode: doc.id,
          ...doc.data(),
        }));

        const { online, offline, admin, supervisor, sales, routes } = processUserActivities(activitiesData, selectedDate);

        setUsers({ online, offline });
        setAdminCount(admin);
        setSupervisorCount(supervisor);
        setSalesCount(sales);
        setAvailableRoutes(routes);
      } catch (error) {
        console.error("Error fetching activities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDate]);

  const getFilteredUsers = () => {
    const allUsers = [...users.online, ...users.offline].filter(user => {
      const matchesSearch = user.userCode.toLowerCase().includes(filters.search.toLowerCase()) ||
                          user.workingRoute.toLowerCase().includes(filters.search.toLowerCase());
      const matchesStatus = filters.status === 'all' || 
                          (filters.status === 'online' && users.online.some(u => u.userCode === user.userCode)) ||
                          (filters.status === 'offline' && users.offline.some(u => u.userCode === user.userCode));
      const matchesType = filters.userType === 'all' || user.userType.toLowerCase() === filters.userType.toLowerCase();
      const matchesRoute = filters.workingRoute === 'all' || user.workingRoute === filters.workingRoute;
      
      return matchesSearch && matchesStatus && matchesType && matchesRoute;
    });
    
    return allUsers;
  };

  const resetFilters = () => {
    setFilters({
      status: 'all',
      userType: 'all',
      workingRoute: 'all',
      search: ''
    });
  };

  const StatusCard = ({ title, count, icon: Icon, color }) => (
    <Card sx={{ 
      background: `linear-gradient(135deg, ${color}15, ${color}05)`,
      border: `1px solid ${color}30`
    }}>
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3 }}>
        <Box>
          <Typography variant="subtitle2" color="text.secondary">{title}</Typography>
          <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>{count}</Typography>
        </Box>
        <Box sx={{ 
          p: 2, 
          borderRadius: '50%', 
          bgcolor: `${color}15`,
          color: color 
        }}>
          <Icon sx={{ fontSize: 32 }} />
        </Box>
      </CardContent>
    </Card>
  );

  const UserCard = ({ user }) => {
    const isOnline = users.online.some(u => u.userCode === user.userCode);
    const getUserTypeIcon = (type) => {
      switch (type.toLowerCase()) {
        case 'admin':
          return <AdminPanelSettings color="primary" />;
        case 'supervisor':
          return <SupervisorAccount color="secondary" />;
        case 'sales':
          return <PersonIcon color="success" />;
        default:
          return <PersonIcon />;
      }
    };

    return (
      <Card sx={{ 
        '&:hover': { 
          boxShadow: 6,
          transform: 'translateY(-2px)',
          transition: 'all 0.3s'
        }
      }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {isOnline ? 
                <Person sx={{ color: 'success.main' }} /> : 
                <PersonOff sx={{ color: 'text.secondary' }} />
              }
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">{user.userCode}</Typography>
                <Chip 
                  size="small"
                  label={isOnline ? 'Online' : 'Offline'}
                  color={isOnline ? 'success' : 'default'}
                />
              </Box>
            </Box>
            {getUserTypeIcon(user.userType)}
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Route fontSize="small" />
              <Typography variant="body2">{user.workingRoute}</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              First Login: {user.loginTime}
            </Typography>
            {(!isOnline || !isSameDay(selectedDate, dayjs())) && user.logoutTime && (
              <Typography variant="body2" color="text.secondary">
                Last Logout: {user.logoutTime}
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary">
              Login Count: {user.loginCount || 0}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Logout Count: {user.logoutCount || 0}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 'xl', mx: 'auto' }}>
      <Typography variant="h4" sx={{ mb: 1 }}>Active Sales Dashboard</Typography>
      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
        Monitor and manage your sales team activities for {selectedDate.format('MMMM D, YYYY')}
      </Typography>

      {/* Date Picker */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Select Date</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            maxDate={dayjs()}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      </Paper>

      {/* Statistics Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <StatusCard 
            title="Online Users" 
            count={users.online.length}
            icon={Person}
            color="#4caf50"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatusCard 
            title="Offline Users" 
            count={users.offline.length}
            icon={PersonOff}
            color="#9e9e9e"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatusCard 
            title="Total Active Users" 
            count={users.online.length + users.offline.length}
            icon={AdminPanelSettings}
            color="#2196f3"
          />
        </Grid>
      </Grid>

      {/* User Type Summary */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>User Types Summary</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography align="center" variant="h5">{adminCount}</Typography>
                <Typography align="center" variant="body2">Admins</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography align="center" variant="h5">{supervisorCount}</Typography>
                <Typography align="center" variant="body2">Supervisors</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography align="center" variant="h5">{salesCount}</Typography>
                <Typography align="center" variant="body2">Sales Representatives</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {/* Filters */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              placeholder="Search users..."
              value={filters.search}
              onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
              InputProps={{
                startAdornment: <Search sx={{ mr: 1, color: 'text.secondary' }} />
              }}
            />
          </Grid>
        
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status}
                label="Status"
                onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
              >
                <MenuItem value="all">All Status</MenuItem>
                <MenuItem value="online">Online</MenuItem>
                <MenuItem value="offline">Offline</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>User Type</InputLabel>
              <Select
                value={filters.userType}
                label="User Type"
                onChange={(e) => setFilters(prev => ({ ...prev, userType: e.target.value }))}
              >
                <MenuItem value="all">All Types</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="supervisor">Supervisor</MenuItem>
                <MenuItem value="sales">Sales</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Working Route</InputLabel>
                <Select
                  value={filters.workingRoute}
                  label="Working Route"
                  onChange={(e) => setFilters(prev => ({ ...prev, workingRoute: e.target.value }))}
                >
                  <MenuItem value="all">All Routes</MenuItem>
                  {[...new Set([...users.online, ...users.offline].map(u => u.workingRoute))].map(route => (
                    <MenuItem key={route} value={route}>{route}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={resetFilters} sx={{ ml: 1 }}>
                <FilterAltOff />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Users Grid */}
      <Grid container spacing={3}>
        {getFilteredUsers().map((user) => (
          <Grid item xs={12} md={6} lg={4} key={user.userCode}>
            <UserCard user={user} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ActiveSalesPage;


