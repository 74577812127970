import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  TableContainer,
  Paper,
  CircularProgress,
  Alert,
  TablePagination,
  Button,
  Card,
  Grid,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Update with your Firebase import path
import { Bar, Pie, Line } from "react-chartjs-2";
import "chart.js/auto";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';

// Import icons
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PeopleIcon from '@mui/icons-material/People';
import TrainIcon from '@mui/icons-material/Train';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Add these new chart components
const LineChart = ({ data }) => (
  <Line
    data={data}
    options={{
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 150
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Daily Ticket Sales Trend'
        }
      }
    }}
  />
);

const TodayTicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filterType, setFilterType] = useState("daily");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [pieData, setPieData] = useState({ labels: [], datasets: [] });
  const [revenueData, setRevenueData] = useState({ labels: [], datasets: [] });
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalSeatCapacity, setTotalSeatCapacity] = useState(0);
  const [totalkm, setTotalkm] = useState(0);
  const [totalStartStations, setTotalStartStations] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const collections = {
    tickets_getout: "Modjo",
    tickets_getout_Alemgena: "Alemgena",
    tickets_getout_sebeta: "Sebeta",
    tickets_getout_CheffeDonsa: "Cheffe-Donsa",
    tickets_getout_leman: "Lemmen",
    tickets_getout_welliso: "Welliso",
    tickets_getout_Tajji: "Tajji",
    tickets_getout_busa: "Busa",
    tickets_getout_sadeen: "Sadeen",
    tickets_getout_Sululta: "Sululta",
    tickets_getout_Burayu: "Burayu",
  };

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      if (!selectedDate) {
        setIsDataLoading(false);
        return;
      }
      
      if (isMounted) {
        setIsDataLoading(true);
        setFilteredTickets([]);
      }
      
      try {
        let allTickets = [];
    
        for (const [collectionName, route] of Object.entries(collections)) {
          const datePath = selectedDate.format("YYYY-MM-DD");
          const docRef = doc(db, collectionName, datePath);
          const docSnap = await getDoc(docRef);
    
          if (docSnap.exists()) {
            const data = docSnap.data();
            
            // Function to process a single ticket
            const processTicket = (ticketData, key, childKey = null) => {
              if (ticketData && typeof ticketData === 'object' && (ticketData.Destination || ticketData["Start Station"])) {
                const parseNumericValue = (value) => {
                  if (typeof value === 'number') return value;
                  if (typeof value === 'string') {
                    return parseFloat(value.replace(',', '.')) || 0;
                  }
                  return 0;
                };

                const id = childKey ? 
                  `${route}-${datePath}-${key}-${childKey}` : 
                  `${route}-${datePath}-${key}`;

                // Get timestamp from Firestore timestamp or string
                const timestamp = ticketData.timestamp?.toDate?.() ? 
                  ticketData.timestamp.toDate() : 
                  new Date(ticketData.timestamp);

                return {
                  ...ticketData,
                  id: id,
                  route: route,
                  "Total Price": parseNumericValue(ticketData["Total Price"]),
                  "Net Price": parseNumericValue(ticketData["Net Price"]),
                  "Price": parseNumericValue(ticketData["Price"]),
                  "Service Charge": parseNumericValue(ticketData["Service Charge"]),
                  "Distance KM": parseNumericValue(ticketData["distance_km"] || ticketData["Distance KM"]),
                  "Seat Capacity": parseInt(ticketData["Seat Capacity"]) || 0,
                  "Total Charge": parseNumericValue(ticketData["Total Charge"]),
                  // Store both timestamp and created_on
                  timestamp: timestamp,
                  created_on: ticketData.created_on
                };
              }
              return null;
            };

            // Process all tickets in the document
            Object.entries(data).forEach(([key, value]) => {
              if (typeof value === 'object') {
                if (value.Destination || value["Start Station"]) {
                  // Direct ticket object
                  const ticket = processTicket(value, key);
                  if (ticket) allTickets.push(ticket);
                } else {
                  // Nested ticket objects
                  Object.entries(value).forEach(([childKey, childValue]) => {
                    const ticket = processTicket(childValue, key, childKey);
                    if (ticket) allTickets.push(ticket);
                  });
                }
              }
            });
          }
        }
    
        if (isMounted) {
          setTickets(allTickets);
          filterTickets(searchTerm, selectedRoute, allTickets);
          setIsDataLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (isMounted) {
          setError(error.message);
          setIsDataLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [selectedDate, filterType]);

  const getDatePaths = (selectedDate, filterType) => {
    let datePaths = [];
    const date = dayjs(selectedDate);

    switch (filterType) {
      case "week":
        const weekStart = date.startOf('week');
        const weekEnd = date.endOf('week');
        for (let d = weekStart; d.isBefore(weekEnd) || d.isSame(weekEnd); d = d.add(1, "day")) {
          datePaths.push(d.format("YYYY-MM-DD"));
        }
        break;
      case "month":
      case "year":
        // Add logic for month/year if needed
        datePaths.push(date.format("YYYY-MM-DD"));
        break;
      default: // daily
        datePaths.push(date.format("YYYY-MM-DD"));
        break;
    }

    return datePaths;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterTickets(event.target.value, selectedRoute);
  };

  const handleRouteChange = (event) => {
    setSelectedRoute(event.target.value);
    filterTickets(searchTerm, event.target.value);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
    const newFilterType = event.target.value;

    // Automatically set date based on filter type
    switch (newFilterType) {
      case "daily":
        setSelectedDate(dayjs());
        break;
      case "week":
        setSelectedDate(dayjs().startOf('week'));
        break;
      case "month":
        setSelectedDate(dayjs().startOf('month'));
        break;
      case "year":
        setSelectedDate(dayjs().startOf('year'));
        break;
      // For "specific", keep the current date
      default:
        break;
    }
  };

  const filterTickets = React.useCallback((search, route, allTickets = tickets) => {
    let filtered = [...allTickets];
    
    if (route && route !== "all") {
      filtered = filtered.filter(ticket => ticket.route === route);
    }

    if (search.trim() !== "") {
      const searchLower = search.toLowerCase();
      filtered = filtered.filter((ticket) =>
        Object.entries(ticket).some(([key, value]) =>
          String(value).toLowerCase().includes(searchLower)
        )
      );
    }

    if (selectedDate) {
      const selectedDay = dayjs(selectedDate).startOf('day');
      filtered = filtered.filter(ticket => {
        const ticketDay = dayjs(ticket.timestamp).startOf('day');
        return ticketDay.isSame(selectedDay, 'day');
      });
    }

    filtered.sort((a, b) => {
      const dateA = new Date(a.created_on || a.timestamp);
      const dateB = new Date(b.created_on || b.timestamp);
      return dateB - dateA;
    });

    setFilteredTickets(filtered);
    setPage(0);
  }, [tickets, searchTerm, selectedRoute, selectedDate, filterType]);

  const getNestedValue = (obj, key) => {
    if (typeof obj === 'object' && obj !== null) {
      if (obj[key] !== undefined) {
        return parseFloat(obj[key]) || 0;
      }
      return Object.values(obj).reduce((acc, value) => acc + getNestedValue(value, key), 0);
    }
    return 0;
  };

  useEffect(() => {
    // Calculate summary totals
    const totalTicketsCount = filteredTickets.length;
    const totalPassengersCount = filteredTickets.reduce(
      (acc, ticket) => acc + parseInt(ticket["Seat Capacity"] || 0, 10),
      0
    );
    const totalDistanceKm = filteredTickets.reduce(
      (acc, ticket) => acc + parseFloat(ticket["Distance KM"] || 0, 10),
      0
    );
    const totalStationsCount = Array.from(
      new Set(filteredTickets.map((ticket) => ticket["Start Station"]))
    ).length;
    const totalChargeValue = filteredTickets.reduce(
      (acc, ticket) => acc + getNestedValue(ticket, "Total Charge"),
      0
    );
    const totalPriceValue = filteredTickets.reduce(
      (acc, ticket) => acc + getNestedValue(ticket, "Total Price"),
      0
    );

    // Calculate route statistics
    const routeStats = {};
    Object.values(collections).forEach(route => {
      routeStats[route] = filteredTickets.filter(ticket => ticket.route === route).length;
    });

    // Calculate ticket distribution
    const ticketDistribution = {};
    filteredTickets.forEach(ticket => {
      const route = ticket.route;
      ticketDistribution[route] = (ticketDistribution[route] || 0) + 1;
    });

    // Calculate revenue by route
    const revenueByRoute = {};
    Object.values(collections).forEach(route => {
      revenueByRoute[route] = filteredTickets
        .filter(ticket => ticket.route === route)
        .reduce((acc, ticket) => acc + parseFloat(ticket["Total Price"] || 0), 0);
    });

    // Update all states
    setTotalTickets(totalTicketsCount);
    setTotalSeatCapacity(totalPassengersCount);
    setTotalkm(totalDistanceKm);
    setTotalStartStations(totalStationsCount);
    setTotalCharge(totalChargeValue);
    setTotalPrice(totalPriceValue);

    setData({
      labels: Object.keys(routeStats),
      datasets: [{
        label: 'Number of Tickets',
        data: Object.values(routeStats),
        backgroundColor: 'rgba(54, 162, 235, 0.8)',
      }],
    });

    setPieData({
      labels: Object.keys(ticketDistribution),
      datasets: [{
        label: 'Ticket Distribution',
        data: Object.values(ticketDistribution),
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(201, 203, 207, 0.8)',
        ],
      }],
    });

    setRevenueData({
      labels: Object.keys(revenueByRoute),
      datasets: [{
        label: 'Revenue',
        data: Object.values(revenueByRoute),
        backgroundColor: 'rgba(255, 205, 86, 0.8)',
      }],
    });

  }, [filteredTickets, collections]); // Add dependencies

  const columns = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 200,
      valueGetter: (params) => {
        if (!params.row?.timestamp) return null;
        return params.row.timestamp instanceof Date ? 
          params.row.timestamp : 
          new Date(params.row.timestamp);
      },
      renderCell: (params) => {
        if (!params.row?.timestamp) return "N/A";
        
        try {
          const date = params.row.timestamp instanceof Date ? 
            params.row.timestamp : 
            new Date(params.row.timestamp);
          
          return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          });
        } catch (error) {
          console.error("Date rendering error:", error);
          return "N/A";
        }
      },
    },
    { field: "Destination", headerName: "Destination", width: 150 },
    { field: "Distance KM", headerName: "Distance KM", width: 150 },
    { field: "Level", headerName: "Level", width: 100 },
    { field: "Net Price", headerName: "Net Price", width: 150 },
    { field: "Plate Number", headerName: "Plate Number", width: 150 },
    { field: "Price", headerName: "Price", width: 150 },
    { field: "Route", headerName: "Route", width: 150 },
    { field: "Sales Name", headerName: "Sales Name", width: 150 },
    { field: "Seat Capacity", headerName: "Seat Capacity", width: 150 },
    { field: "Service Charge", headerName: "Service Charge", width: 150 },
    { field: "Start Station", headerName: "Start Station", width: 150 },
    { field: "Total Charge", headerName: "Total Charge", width: 150 },
    { field: "Total Price", headerName: "Total Price", width: 150 },
    { field: "userCode", headerName: "User Code", width: 150 },
  ];

  // Add this to verify Route Statistics data
  const routeStatisticsData = {
    labels: Object.values(collections),
    datasets: [
      {
        label: "Total Tickets",
        data: Object.values(collections).map(
          (route) => tickets.filter((ticket) => ticket.route === route).length
        ),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      // ... other datasets ...
    ],
  };

  console.log('Route Statistics data:', routeStatisticsData);

  // Add these new styles
  const styles = {
    pageContainer: {
      backgroundColor: '#ffffff', // White background
      minHeight: '100vh',
      padding: '24px',
      color: '#333', // Dark text for contrast
      fontFamily: 'Roboto, sans-serif', // Modern font
    },
    header: {
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '16px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)', // Subtle border
    },
    filterContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '20px',
      marginBottom: '32px',
      backgroundColor: '#f0f0f0', // Light gray card background
      padding: '24px',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Soft shadow
      
    },
    summaryCard: {
      backgroundColor: '#f0f0f0', // Light gray card background
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Soft shadow
      padding: '32px',
      marginBottom: '32px',
    },
    summaryItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#ffffff', // White item background
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Soft shadow
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)', // Slight hover effect
        boxShadow: '0 4px 12px rgba(0,0,0,0.2)', // Enhanced shadow on hover
      },
    },
    chartContainer: {
      backgroundColor: '#f0f0f0', // Light gray card background
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Soft shadow
      padding: '32px',
      marginBottom: '32px',
      height: '550px',
    },
    smallChartContainer: {
      backgroundColor: '#f0f0f0', // Light gray card background
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Soft shadow
      padding: '24px',
      marginBottom: '24px',
      height: '450px',
    },
    kpiValue: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#007bff', // Modern blue accent color
      marginBottom: '8px',
    },
    kpiLabel: {
      fontSize: '1.1rem',
      color: 'rgba(0, 0, 0, 0.5)', // Darker label for light theme
    },
    chartTitle: {
      color: '#333', // Dark chart title
      marginBottom: '24px',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };

  if (isDataLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box sx={styles.pageContainer}>
      {isDataLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Box sx={styles.header}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          Tickets Dashboard
        </Typography>
        <CSVLink
          data={filteredTickets}
          filename={`tickets_${selectedDate?.format("YYYY-MM-DD")}.csv`}
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            sx={{
              backgroundColor: '#007bff',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            Export CSV
          </Button>
        </CSVLink>
      </Box>

      <Box sx={styles.filterContainer}>
        <TextField
          fullWidth
          label="Search Tickets"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          select
          label="Route"
          value={selectedRoute}
          onChange={handleRouteChange}
          variant="outlined"
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                },
              },
            },
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.5)' }
          }}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#007bff',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#007bff',
            },
          }}
        >
          <MenuItem value="all" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>All Routes</MenuItem>
          {Object.values(collections).map((route) => (
            <MenuItem key={route} value={route} sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>
              {route}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Journey"
            value={selectedDate}
            onChange={handleDateChange}
            slotProps={{
              textField: {
                variant: 'outlined',
                InputProps: {},
                InputLabelProps: {
                  style: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                sx: {
                  svg: { color: 'rgba(0, 0, 0, 0.5)' },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#007bff',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#007bff',
                  },
                }
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  },
                  '& .MuiButtonBase-root': {
                    color: '#333',
                    '&.Mui-selected': {
                      backgroundColor: '#007bff !important',
                      color: '#fff !important',
                      '&:hover': {
                        backgroundColor: '#0056b3 !important',
                      },
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
                    },
                  },
                  '& .MuiIconButton-root': {
                    color: '#333',
                  },
                },
              },
            }}
          />
        </LocalizationProvider>

        <TextField
          fullWidth
          select
          label="Time Period"
          value={filterType}
          onChange={handleFilterTypeChange}
          variant="outlined"
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  backgroundColor: '#f0f0f0',
                  color: '#333',
                },
              },
            },
          }}
          InputLabelProps={{
            style: { color: 'rgba(0, 0, 0, 0.5)' }
          }}
          sx={{
            color: '#333',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#007bff',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#007bff',
            },
          }}
        >
          <MenuItem value="daily" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>Daily</MenuItem>
          <MenuItem value="week" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>Weekly</MenuItem>
          <MenuItem value="month" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>Monthly</MenuItem>
          <MenuItem value="year" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>Yearly</MenuItem>
          <MenuItem value="specific" sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#e0e0e0' } }}>Custom Range</MenuItem>
        </TextField>
      </Box>

      <Grid container spacing={3} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#e3f2fd', padding: '24px' }}>
            <ConfirmationNumberIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#1976d2' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#1976d2' }}>{totalTickets}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Tickets</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#f8bbd0', padding: '24px' }}>
            <PeopleIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#c2185b' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#c2185b' }}>{totalSeatCapacity}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Passengers</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#dcedc8', padding: '24px' }}>
            <TrainIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#558b2f' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#558b2f' }}>{totalkm}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Distance KM</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#ffecb3', padding: '24px' }}>
            <TrainIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#f9a825' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#f9a825' }}>{totalStartStations}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Stations</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#ffe0b2', padding: '24px' }}>
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#ef6c00' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#ef6c00' }}>${totalCharge.toFixed(2)}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Charge</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ ...styles.summaryItem, backgroundColor: '#f0f4c3', padding: '24px' }}>
            <AttachMoneyIcon color="primary" sx={{ fontSize: 40, mb: 1, color: '#c0ca33' }} />
            <Typography variant="h6" sx={{ ...styles.kpiValue, color: '#c0ca33' }}>${totalPrice.toFixed(2)}</Typography>
            <Typography variant="body2" sx={styles.kpiLabel}>Total Price</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={styles.chartContainer}>
            <Typography variant="h6" gutterBottom sx={styles.chartTitle}>
              Route Statistics
            </Typography>
            <Bar
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 150
                },
                color: '#333',
                scales: {
                  x: {
                    ticks: { color: '#333' },
                    grid: { color: 'rgba(0, 0, 0, 0.1)' }
                  },
                  y: {
                    ticks: { color: '#333' },
                    grid: { color: 'rgba(0, 0, 0, 0.1)' }
                  },
                },
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      color: '#333'
                    }
                  },
                  tooltip: {
                    backgroundColor: '#ffffff',
                    titleColor: '#333',
                    bodyColor: '#333',
                    borderColor: '#007bff',
                    borderWidth: 1,
                  },
                  title: {
                    display: false,
                  },
                },
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.smallChartContainer}>
            <Typography variant="h6" gutterBottom sx={styles.chartTitle}>
              Ticket Distribution
            </Typography>
            <Pie
              data={pieData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 150
                },
                color: '#333',
                plugins: {
                  legend: {
                    position: 'right',
                    labels: {
                      color: '#333'
                    }
                  },
                  tooltip: {
                    backgroundColor: '#ffffff',
                    titleColor: '#333',
                    bodyColor: '#333',
                    borderColor: '#007bff',
                    borderWidth: 1,
                  },
                  title: {
                    display: false,
                  },
                },
              }}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.smallChartContainer}>
            <Typography variant="h6" gutterBottom sx={styles.chartTitle}>
              Revenue by Route
            </Typography>
            <Bar
              data={revenueData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                animation: {
                  duration: 150
                },
                color: '#333',
                scales: {
                  x: {
                    ticks: { color: '#333' },
                    grid: { color: 'rgba(0, 0, 0, 0.1)' }
                  },
                  y: {
                    ticks: { color: '#333' },
                    grid: { color: 'rgba(0, 0, 0, 0.1)' }
                  },
                },
                plugins: {
                  legend: {
                    position: 'top',
                    labels: {
                      color: '#333'
                    }
                  },
                  tooltip: {
                    backgroundColor: '#ffffff',
                    titleColor: '#333',
                    bodyColor: '#333',
                    borderColor: '#007bff',
                    borderWidth: 1,
                  },
                  title: {
                    display: false,
                  },
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ mt: 4, backgroundColor: '#f0f0f0', color: '#333', boxShadow: '0 2px 8px rgba(0,0,0,0.1)', borderRadius: '12px' }}>
        <DataGrid
          rows={filteredTickets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25]}
          checkboxSelection
          disableRowSelectionOnClick
          autoHeight
          getRowId={(row) => row.id}
          loading={isDataLoading}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: () => (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: '#333' }}>
                <Typography>No tickets found</Typography>
              </Box>
            ),
          }}
          sx={{
            '& .MuiDataGrid-cell': {
              color: '#333',
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#ffffff',
              color: '#333',
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '& .MuiDataGrid-row': {
              '&:nth-of-type(even)': {
                backgroundColor: 'rgba(240, 240, 240, 0.7)',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 123, 255, 0.08)',
                color: '#007bff',
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: '#f0f0f0',
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: '#ffffff',
              color: '#333',
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '& .MuiCheckbox-root': {
              color: '#333',
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: '#333 !important',
            },
          }}
        />
      </Paper>
    </Box>
  );
};

const CustomLoadingOverlay = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      sx={{ color: '#333' }}
    >
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default TodayTicketsPage;
