import React, { useEffect, useState } from "react";
import { images } from "../../assets";
import { Box, Grid, Stack, Typography, colors } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import the Firestore instance
import Animate from "./Animate";
import MPaper from "./MPaper";

const SummaryGrid = () => {
  const [summaryData, setSummaryData] = useState([
    {
      title: "Total Sales Today",
      value: "Loading...",
      image: images.summaryImages.totalBook,
    },
    {
      title: "Total Passengers",
      value: "Loading...",
      image: images.summaryImages.sold,
    },
    {
      title: "Total Cars",
      value: "Loading...",
      image: images.summaryImages.cancel,
    },
  ]);

  const [isOnline, setIsOnline] = useState(true); // State to track network status

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collections = {
          tickets_getout: "Modjo",
          tickets_getout_Alemgena: "Alemgena",
          tickets_getout_sebeta: "Sebeta",
          tickets_getout_CheffeDonsa: "CheffeDonsa",
          tickets_getout_leman: "Lemmen",
          tickets_getout_welliso: "Welliso",
          tickets_getout_Tajji: "Tajji",
          tickets_getout_busa: "Busa",
          tickets_getout_sadeen: "Sadeen",
          tickets_getout_Sululta: "Sululta",
          tickets_getout_Burayu: "Burayu",
        };

        const totalServiceCharges = {
          Modjo: 0,
          Alemgena: 0,
          Sebeta: 0,
          CheffeDonsa: 0,
          Lemmen: 0,
          Welliso: 0,
          Tajji: 0,
          Busa: 0,
          Sadeen: 0,
          Sululta: 0,
          Burayu: 0,
        };

        const totalSeatCapacity = {
          Modjo: 0,
          Alemgena: 0,
          Sebeta: 0,
          CheffeDonsa: 0,
          Lemmen: 0,
          Welliso: 0,
          Tajji: 0,
          Busa: 0,
          Sadeen: 0,
          Sululta: 0,
          Burayu: 0,
        };

        const totalCars = {
          Modjo: 0,
          Alemgena: 0,
          Sebeta: 0,
          CheffeDonsa: 0,
          Lemmen: 0,
          Welliso: 0,
          Tajji: 0,
          Busa: 0,
          Sadeen: 0,
          Sululta: 0,
          Burayu: 0,
        };

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
        const yyyy = today.getFullYear();
        const todayString = yyyy + "-" + mm + "-" + dd;

        const accumulateChargesAndSeats = (data) => {
          let charges = 0;
          let seats = 0;
          let carCount = 0;

          const traverse = (obj) => {
            if (obj && typeof obj === 'object') {
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  if (typeof obj[key] === 'object') {
                    traverse(obj[key]);
                  } else if (key === "Total Charge") {
                    const charge = parseFloat(obj[key]);
                    if (!isNaN(charge)) {
                      charges += charge;
                    }
                  } else if (key === "Seat Capacity") {
                    const seat = parseInt(obj[key], 10);
                    if (!isNaN(seat)) {
                      seats += seat;
                    }
                  }
                }
              }
            }
          };

          traverse(data);
          carCount = Object.keys(data).length;

          return { charges, seats, carCount };
        };

        for (const [collectionName, route] of Object.entries(collections)) {
          const docRef = doc(db, collectionName, todayString);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            const { charges, seats, carCount } = accumulateChargesAndSeats(data);

            totalServiceCharges[route] += charges;
            totalSeatCapacity[route] += seats;
            totalCars[route] += carCount;
          }
        }

        const totalServiceCharge =
          totalServiceCharges.Modjo +
          totalServiceCharges.Alemgena +
          totalServiceCharges.Sebeta +
          totalServiceCharges.CheffeDonsa +
          totalServiceCharges.Lemmen +
          totalServiceCharges.Welliso +
          totalServiceCharges.Tajji +
          totalServiceCharges.Busa +
          totalServiceCharges.Sadeen +
          totalServiceCharges.Sululta +
          totalServiceCharges.Burayu;

        const overallTotalSeatCapacity =
          totalSeatCapacity.Modjo +
          totalSeatCapacity.Alemgena +
          totalSeatCapacity.Sebeta +
          totalSeatCapacity.CheffeDonsa +
          totalSeatCapacity.Lemmen +
          totalSeatCapacity.Welliso +
          totalSeatCapacity.Tajji +
          totalServiceCharges.Busa +
          totalServiceCharges.Sadeen +
          totalServiceCharges.Sululta +
          totalServiceCharges.Burayu;

        const overallTotalCars =
          totalCars.Modjo +
          totalCars.Alemgena +
          totalCars.Sebeta +
          totalCars.CheffeDonsa +
          totalCars.Lemmen +
          totalCars.Welliso +
          totalCars.Tajji +
          totalCars.Busa +
          totalCars.Sadeen +
          totalCars.Sululta +
          totalCars.Burayu;


        setSummaryData([
          {
            title: "Total Sales Today",
            value: `${totalServiceCharge.toFixed(2)}birr`,
            image: images.summaryImages.totalBook,
          },
          {
            title: "Total Passengers",
            value: `${overallTotalSeatCapacity}k`,
            image: images.summaryImages.sold,
          },
          {
            title: "Total Cars",
            value: `${overallTotalCars}`,
            image: images.summaryImages.cancel,
          },
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsOnline(false); // Set network status to offline on error
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {!isOnline && (
        <div style={{ textAlign: "center", margin: "20px 0", color: "red" }}>
          Failed to fetch data. Please check your internet connection and{" "}
          <button onClick={() => window.location.reload()}>reload</button>.
        </div>
      )}
      <Grid container spacing={3}>
        {summaryData.map((summary, index) => (
          <Grid key={index} item xs={12} lg={4}>
            <Animate type="fade" delay={(index + 1) / 3}>
              <MPaper>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack spacing={1}>
                    <Typography variant="h4" fontWeight="bold">
                      {summary.value}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color={colors.grey[600]}
                    >
                      {summary.title}
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      height: "100px",
                      width: "100px",
                      "& img": { width: "100%" },
                    }}
                  >
                    <img src={summary.image} alt="summary" />
                  </Box>
                </Stack>
              </MPaper>
            </Animate>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SummaryGrid;
